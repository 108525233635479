import memoize from 'lodash/memoize'
import { ContextApi } from '@pancakeswap/localization'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'Obsidian Smart Dex Aggregaror and DeFi ecosystem, Swap, Stake, LaunchPad',
  description:
    'Smart Dex Aggregaror ecosystem with Swap, Stake and Launchpad. Trade, Stake, Earn and Launch on Cronos blockchain',
  image: 'https://obsidian.finance/images/obsidian.png',
}

interface PathList {
  paths: { [path: string]: { title: string; basePath?: boolean; description?: string } }
  defaultTitleSuffix: string
}

const getPathList = (t: ContextApi['t']): PathList => {
  return {
    paths: {
      '/home': { title: t('Home') },
      '/': { basePath: true, title: t('Exchange')},
      '/swap': { basePath: true, title: t('Exchange') },
      '/add': { basePath: true, title: t('Add Liquidity') },
      '/remove': { basePath: true, title: t('Remove Liquidity') },
      '/liquidity': { title: t('Liquidity') },
      '/find': { title: t('Import Pool') },
      '/farms': { title: t('Farms') },
      '/pools': { title: t('Pools') },
      '/listing': { title: t('Token Listing Request'), description: 'List your token on Obsidian with ease!'   },
      '/battle': { title: t('Tokens Battle!') },
      '/leaderboard': { title: t('Trading Leaderboard'), description: 'The more you trade, the more you win!'  },
      //'/info': { title: t('Overview'), description: 'View statistics for Obsidian exchanges.' },
      //'/info/pools': { title: t('Pools'), description: 'View statistics for Obsidian exchanges.' },
     // '/info/tokens': { title: t('Tokens'), description: 'View statistics for Obsidian exchanges.' },
      // '/core': { basePath: true, title: t('Get Ready for Core 🚀') },
     /* '/bridge': {
        basePath: true,
        title: t('Bridge'),
        description:
          'Transfer tokens between multiple Chains including Core, Bitgert, Binance, XDC, Fuse and may more on Obsidian DEX.',
      },
      */
    },
    defaultTitleSuffix: t('Obsidian Finance'),
  }
}

export const getCustomMeta = memoize(
  (path: string, t: ContextApi['t'], _: string): PageMeta => {
    const pathList = getPathList(t)
    const pathMetadata =
      pathList.paths[path] ??
      pathList.paths[Object.entries(pathList.paths).find(([url, data]) => data.basePath && path.startsWith(url))?.[0]]

    if (pathMetadata) {
      return {
        title: `${pathMetadata.title} | ${t(pathList.defaultTitleSuffix)}`,
        ...(pathMetadata.description && { description: pathMetadata.description }),
      }
    }
    return null
  },
  (path, t, locale) => `${path}#${locale}`,
)
