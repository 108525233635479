import { useTranslation } from '@pancakeswap/localization'
import { CHAIN_QUERY_NAME } from 'config/chains'
import { WalletConnectorNotFoundError, WalletSwitchChainError } from '@pancakeswap/ui-wallets'
import replaceBrowserHistory from '@pancakeswap/utils/replaceBrowserHistory'
import { ConnectorNames } from 'config/wallet'
import { useCallback } from 'react'
import { useAppDispatch } from 'state'
import { ConnectorNotFoundError, SwitchChainNotSupportedError, useConnect, useDisconnect, useNetwork } from 'wagmi'
import { clearUserStates } from '../utils/clearUserStates'
import { useActiveChainId } from './useActiveChainId'
import { useSessionChainId } from './useSessionChainId'

// Storage keys that need to be cleared
const STORAGE_KEYS_TO_CLEAR = [
  // Wallet related
  'wagmi.wallet',
  'wagmi.connected',
  'wagmi.injected.shimDisconnect',
  'wagmi_v1.1.wallet',
  'wagmi_v1.1.cache',
  'wagmi_v1.1.store',
  'wallet',
  
  // WalletConnect related
  'wc@2:client:0.3//proposal',
  'wc@2:core:0.3//expirer',
  'wc@2:core:0.3//history',
  'wc@2:core:0.3//keychain',
  'wc@2:core:0.3//messages',
  'wc@2:core:0.3//pairing',
  'wc@2:core:0.3//subscription',
  'wc@2:universal_provider:/namespaces',
  'wc@2:universal_provider:/optionalNamespaces',
  'WCM_VERSION',
  
  // Walletlink related
  '-walletlink:https://www.walletlink.org:session:id',
  '-walletlink:https://www.walletlink.org:session:linked',
  '-walletlink:https://www.walletlink.org:session:secret',
  '-walletlink:https://www.walletlink.org:version',
  
  // App specific
  'cdc.wallet.providerConfig',
  'persist:primary',
  'theme',
] as const

// Patterns to match for clearing
const STORAGE_PATTERNS_TO_CLEAR = [
  /^wagmi/,
  /^wc@2/,
  /^-walletlink/,
  /^pcs:/,
  /^__next_scroll_/,
] as const

const clearAllStorage = () => {
  try {
    // Clear specific keys
    STORAGE_KEYS_TO_CLEAR.forEach((key) => {
      try {
        window?.localStorage?.removeItem(key)
        window?.sessionStorage?.removeItem(key)
      } catch (error) {
        console.error(`Failed to remove ${key}:`, error)
      }
    })

    // Clear pattern-matching keys from localStorage
    Object.keys(window?.localStorage || {}).forEach((key) => {
      if (STORAGE_PATTERNS_TO_CLEAR.some(pattern => pattern.test(key))) {
        window?.localStorage?.removeItem(key)
      }
    })

    // Clear pattern-matching keys from sessionStorage
    Object.keys(window?.sessionStorage || {}).forEach((key) => {
      if (STORAGE_PATTERNS_TO_CLEAR.some(pattern => pattern.test(key))) {
        window?.sessionStorage?.removeItem(key)
      }
    })

    // Clear any remaining WalletConnect sessions
    const wcKeys = Object.keys(window?.localStorage || {}).filter(
      key => key.includes('walletconnect') || key.includes('WalletConnect')
    )
    wcKeys.forEach(key => window?.localStorage?.removeItem(key))

  } catch (error) {
    console.error('Failed to clear storage:', error)
  }
}

const useAuth = () => {
  const dispatch = useAppDispatch()
  const { connectAsync, connectors } = useConnect()
  const { chain } = useNetwork()
  const { disconnectAsync } = useDisconnect()
  const { chainId } = useActiveChainId()
  const [, setSessionChainId] = useSessionChainId()
  const { t } = useTranslation()

  const login = useCallback(
    async (connectorID: ConnectorNames) => {
      const findConnector = connectors.find((c) => c.id === connectorID)
      try {
        const connected = await connectAsync({ connector: findConnector, chainId })
        if (!connected.chain.unsupported && connected.chain.id !== chainId) {
          replaceBrowserHistory('chain', CHAIN_QUERY_NAME[connected.chain.id])
          setSessionChainId(connected.chain.id)
        }
        return connected
      } catch (error) {
        if (error instanceof ConnectorNotFoundError) {
          throw new WalletConnectorNotFoundError()
        }
        if (error instanceof SwitchChainNotSupportedError) {
          throw new WalletSwitchChainError(t('Unable to switch network. Please try it on your wallet'))
        }
      }
      return undefined
    },
    [connectors, connectAsync, chainId, setSessionChainId, t],
  )

  const logout = useCallback(async () => {
    try {
      // Disconnect from wallet
      await disconnectAsync()
    } catch (error) {
      console.error('Disconnect error:', error)
    } finally {
      // Clear all user states
      clearUserStates(dispatch, { chainId: chain?.id })
      
      // Clear all storage
      clearAllStorage()
      
      // Force clear provider connection
      if (window.ethereum) {
        try {
          // @ts-ignore
          if (window.ethereum._state && typeof window.ethereum._state.handleDisconnect === 'function') {
            // @ts-ignore
            window.ethereum._state.handleDisconnect()
          }
        } catch (error) {
          console.error('Failed to disconnect provider:', error)
        }
      }
    }
  }, [disconnectAsync, dispatch, chain?.id])

  return { login, logout }
}

export default useAuth