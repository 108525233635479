import { __spreadProps, __spreadValues, __privateAdd, __privateSet, __privateGet, __async, __privateMethod } from '../chunk-APHU2MIK.mjs';
import { Connector } from 'wagmi';
import { getAddress, SwitchChainError, numberToHex, UserRejectedRequestError, createWalletClient, custom } from 'viem';
import { DeFiConnectProvider } from '@deficonnect/provider';

function parseSendReturn(sendReturn) {
  return sendReturn.hasOwnProperty("result") ? sendReturn.result : sendReturn;
}
var _initProviderPromise, _provider, _createProvider, createProvider_fn;
var DefiWalletConnector = class extends Connector {
  constructor(config) {
    super(__spreadProps(__spreadValues({}, config), {
      options: __spreadValues({}, config.options)
    }));
    __privateAdd(this, _createProvider);
    this.id = "defiWallet";
    this.name = "Crypto.com | ONCHAIN";
    this.ready = true;
    this.connected = false;
    __privateAdd(this, _initProviderPromise, void 0);
    __privateAdd(this, _provider, void 0);
    this.onAccountsChanged = (accounts) => {
      if (accounts.length === 0)
        this.emit("disconnect");
      else {
        let account = accounts[0];
        if (typeof account === "string" && !account.startsWith("0x")) {
          account = `0x${account}`;
        }
        this.emit("change", {
          account: getAddress(account)
        });
      }
    };
    this.onChainChanged = (chainId) => {
      const id = Number(chainId);
      const unsupported = this.isChainUnsupported(id);
      this.emit("change", { chain: { id, unsupported } });
    };
    this.onDisconnect = () => {
      this.deactivate();
    };
    if (typeof window !== "undefined") {
      __privateSet(this, _provider, new DeFiConnectProvider(this.options));
    }
  }
  handleChainChanged(chainId) {
    this.emitUpdate({ chainId, provider: __privateGet(this, _provider) });
  }
  getProvider() {
    return __async(this, arguments, function* ({ chainId } = {}) {
      if (!__privateGet(this, _provider))
        yield __privateMethod(this, _createProvider, createProvider_fn).call(this);
      if (chainId)
        yield this.switchChain(chainId);
      return __privateGet(this, _provider);
    });
  }
  switchChain(chainId) {
    return __async(this, null, function* () {
      const chain = this.chains.find((chain2) => chain2.id === chainId);
      if (!chain)
        throw new SwitchChainError(new Error("chain not found on connector."));
      try {
        const provider = yield this.getProvider();
        yield provider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: numberToHex(chainId) }]
        });
        return chain;
      } catch (error) {
        const message = typeof error === "string" ? error : error == null ? void 0 : error.message;
        if (/user rejected request/i.test(message)) {
          throw new UserRejectedRequestError(error);
        }
        throw new SwitchChainError(error);
      }
    });
  }
  // Implement other methods
  connect() {
    return __async(this, null, function* () {
      var _a, _b;
      try {
        if (!this.connected) {
          this.emit("message", { type: "connecting" });
          yield (_a = __privateGet(this, _provider)) == null ? void 0 : _a.connect(this.options);
        }
        const accounts = yield (_b = __privateGet(this, _provider)) == null ? void 0 : _b.enable();
        const account = getAddress(accounts[0]);
        const chainId = yield this.getChainId();
        const provider = yield this.getProvider();
        if (provider.on) {
          provider.on("accountsChanged", this.onAccountsChanged);
          provider.on("chainChanged", this.handleChainChanged);
          provider.on("disconnect", this.onDisconnect);
          provider.on("networkChanged", this.onChainChanged);
        }
        this.connected = true;
        return {
          account,
          chain: {
            id: chainId,
            unsupported: this.isChainUnsupported(chainId)
          }
        };
      } catch (error) {
        if (/user rejected/i.test(error == null ? void 0 : error.message)) {
          throw new UserRejectedRequestError(error);
        }
        throw error;
      }
    });
  }
  disconnect() {
    return __async(this, null, function* () {
      const provider = yield this.getProvider();
      try {
        provider.close;
        this.emit("disconnect");
      } catch (error) {
        if (!/No matching key/i.test(error.message))
          throw error;
      } finally {
        provider.removeListener;
      }
    });
  }
  getAccount() {
    return __async(this, null, function* () {
      var _a;
      const accounts = yield (_a = __privateGet(this, _provider)) == null ? void 0 : _a.enable();
      return getAddress(accounts[0]);
    });
  }
  getChainId() {
    return __async(this, null, function* () {
      if (!__privateGet(this, _provider))
        throw new Error("provider is required.");
      let chainId;
      try {
        chainId = yield __privateGet(this, _provider).request({ method: "eth_chainId" }).then(parseSendReturn);
      } catch (e) {
        console.warn(false, "eth_chainId was unsuccessful, falling back to net_version");
      }
      if (!chainId) {
        try {
          chainId = yield __privateGet(this, _provider).request({ method: "net_version" }).then(parseSendReturn);
        } catch (e) {
          console.warn(false, "net_version was unsuccessful, falling back to net version v2");
        }
      }
      if (!chainId) {
        chainId = __privateGet(this, _provider).chainId;
      }
      return parseInt(chainId, 16);
    });
  }
  isAuthorized() {
    return __async(this, null, function* () {
      try {
        const [provider, account] = yield Promise.all([
          this.getProvider(),
          this.getAccount()
        ]);
        if (!account)
          return false;
        return true;
      } catch (e) {
        return false;
      }
    });
  }
  getWalletClient() {
    return __async(this, arguments, function* ({ chainId } = {}) {
      const [provider, account] = yield Promise.all([
        this.getProvider({ chainId }),
        this.getAccount()
      ]);
      const chain = this.chains.find((x) => x.id === chainId);
      if (!provider)
        throw new Error("provider is required.");
      return createWalletClient({
        account,
        chain,
        transport: custom(provider)
      });
    });
  }
  deactivate() {
    this.emit("disconnect");
    __privateGet(this, _provider).close();
  }
};
_initProviderPromise = new WeakMap();
_provider = new WeakMap();
_createProvider = new WeakSet();
createProvider_fn = function() {
  return __async(this, null, function* () {
    if (!__privateGet(this, _initProviderPromise) && typeof window !== "undefined") {
      __privateSet(this, _provider, new DeFiConnectProvider(this.options));
    }
    return __privateGet(this, _provider);
  });
};

export { DefiWalletConnector };
